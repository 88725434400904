<template>
  <v-container fluid style="background: white">
    <v-row>
      <v-col class="text-center" sm="12" cols="12">
        <div style="color: #d0282e; max-width: 700px; margin: auto">
          <p class="display-2 font-weight-bold">PANEL DE USUARIO</p>
          <p class="title font-weight-light">
            Al ser usuario de SOSclick tendrás acceso a nuestro
            <b>Panel de Usuario</b>, en el cual podrás configurar y activar
            todas las funcionalidades de SOSclick
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0" sm="12" cols="12">
        <v-img
          eager
          :style="!$vuetify.breakpoint.mobile ? '' : 'margin-left:10px'"
          class="rounded-xl"
          width="100%"
          height="auto"
          :src="require('../../assets/imgs/PanelCorrecto.png')"
        />
      </v-col>
    </v-row>

    <v-row class="align-end py-10" style="background: #d32527">
      <v-col sm="3" cols="12">
        <v-container :class="!$vuetify.breakpoint.mobile ? 'ml-16' : ''">
          <div class="text-center">
            <v-img
              eager
              class="mx-auto"
              width="160"
              height="auto"
              :src="require('../../assets/imgs/icon-bloqueo.svg')"
            />
          </div>
          <div class="mt-5 mx-auto" style="max-width: 250px">
            <p class="headline text-center font-weight-bold white--text">
              Bloqueo (Sólo Android)
            </p>
            <p class="white--text text-center">
              Podrás bloquear el dispositivo remotamente, si no le habías
              asignado clave a tu dispositivo podrás generar una para que sea
              solicitada antes de permitir el desbloqueo.
            </p>
          </div>
        </v-container>
      </v-col>
      <v-col
        sm="9"
        cols="12"
        :style="!$vuetify.breakpoint.mobile ? 'position:relative;' : ''"
      >
        <v-img
          eager
          :style="
            !$vuetify.breakpoint.mobile
              ? 'max-width: 800px; position: absolute;margin: auto; bottom: 0; left: 0; right: 0;'
              : ''
          "
          height="auto"
          :src="require('../../assets/imgs/Msnbloqueo.svg')"
        />
      </v-col>
    </v-row>

    <v-row class="align-center" style="background: #f7f7f7">
      <v-col class="pb-0 pt-10" cols="12" sm="8">
        <v-img
          eager
          class="mt-7"
          style="margin-left: 15%"
          width="70%"
          height="auto"
          :src="require('../../assets/imgs/PerdidaDispositivoCorrecto.svg')"
        />
      </v-col>

      <v-col class="text-start" cols="12" sm="4">
        <v-container>
          <div class="text-center">
            <v-img
              eager
              class="mx-auto"
              width="160"
              height="auto"
              :src="require('../../assets/imgs/icon-perdida.svg')"
            />
          </div>
          <div class="mt-5 mx-auto" style="max-width: 300px">
            <p
              class="headline text-center font-weight-bold"
              style="color: #d32527"
            >
              Pérdida/Robo (Sólo Android)
            </p>
            <p
              class="text-center font-weight-regular subtitle-1"
              style="color: #707070"
            >
              Si se te pierde o te roban tu dispositivo podrás enviar una
              solicitud para que el teléfono te envíe (cada 1 hr) una foto de la
              cámara frontal, una de la cámara trasera y la posición del
              dispositivo.
            </p>
            <v-btn
              large
              rounded
              small
              block
              color="white"
              @click="
                $store.commit('setModalVideoState', {
                  videoID: 'CqPmrL-uuLA',
                  open: true,
                })
              "
            >
              <p
                class="mb-0 text-primary"
                :class="!$vuetify.breakpoint.mobile ? 'mr-3' : 'mr-0 caption'"
              >
                Ver Video
              </p>
              <v-icon class="text-primary">mdi-play</v-icon>
            </v-btn>
          </div>
        </v-container>
      </v-col>
    </v-row>

    <v-row style="height: 85vh">
      <v-col sm="3" cols="12">
        <v-container
          :class="!$vuetify.breakpoint.mobile ? 'mt-16 ml-16' : ''"
          :style="!$vuetify.breakpoint.mobile ? '' : 'padding-left:60px'"
        >
          <div
            :class="!$vuetify.breakpoint.mobile ? 'ml-4' : ''"
            :style="!$vuetify.breakpoint.mobile ? '' : 'margin-right:20%'"
          >
            <v-img
              eager
              class="mx-auto"
              width="200"
              height="auto"
              :src="require('../../assets/imgs/IconBorrado.svg')"
            />
          </div>
          <div
            :class="!$vuetify.breakpoint.mobile ? 'mx-auto' : ''"
            :style="
              !$vuetify.breakpoint.mobile
                ? 'max-width:280px'
                : 'max-width:300px'
            "
          >
            <p
              class="headline text-center font-weight-bold"
              style="color: #707070"
            >
              BORRADO DE TELÉFONO
            </p>
            <p style="color: #838383">
              Podrás borrar la información de tu teléfono remotamente (volviendo
              a un estado de fábrica). Esto es recomendado sólo si no crees
              posible recuperar tu teléfono y/o si necesitas proteger
              información privada o delicada.
            </p>
          </div>
        </v-container>
      </v-col>

      <v-col class="px-0" sm="9" cols="12">
        <v-img
          eager
          :class="!$vuetify.breakpoint.mobile ? '' : ''"
          :style="!$vuetify.breakpoint.mobile ? 'margin-left:15%' : ''"
          width="100%"
          height="auto"
          :src="require('../../assets/imgs/BorradoTelefono.png')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>