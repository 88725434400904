<template>
  <v-container fluid class="pb-0" style="background: #ffffff">
    <v-row>
      <v-col cols="12" class="text-center justify-center align-center">
        <v-img
          eager
          max-width="80"
          :src="require('../../assets/imgs/dashcam.svg')"
          class="mx-auto"
        ></v-img>
        <h2 class="display-1 font-weight-bold text-primary">DASHCAM</h2>
        <p
          class="p4 my-5 mx-auto title font-weight-regular"
          :style="$vuetify.breakpoint.mobile ? '' : 'max-width: 50%;'"
        >
          Convierte tu teléfono en una Dashcam y graba lo que ves mientras
          conduces, puedes manejar las funciones de inicio, guardado de videos y
          finalización con comandos de voz.
        </p>
        <v-img
          eager
          style="
            max-width: 50%;
            position: absolute;
            z-index: 1;
            margin-top: 45.9%;
            left: 63%;
          "
          :src="require('../../assets/imgs/DashAuto.svg')"
          class="mx-auto d-none d-sm-flex"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-img eager :src="require('../../assets/imgs/dashb.jpg')"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Dashcam",
};
</script>

<style>
.p3 {
  color: #d32527;
}
.p4 {
  text-align: center;
  letter-spacing: 0px;
  color: #838383;
  opacity: 1;
}
</style>