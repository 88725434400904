import { render, staticRenderFns } from "./Wheel.vue?vue&type=template&id=7e4cfb0d&scoped=true"
import script from "./Wheel.vue?vue&type=script&lang=js"
export * from "./Wheel.vue?vue&type=script&lang=js"
import style0 from "./Wheel.vue?vue&type=style&index=0&id=7e4cfb0d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e4cfb0d",
  null
  
)

export default component.exports