<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
    @input="changeDrawer"
    class="px-5"
    style="background-color: #D0282E!important;">
    <v-list-item>
      <v-list-item-content class="my-2 text-center justify-center">
        <v-img
          alt="SOSCLICK Logo"
          class="shrink"
          contain
          :src="require('../../assets/blanco.png')"
          transition="scale-transition"
          max-width="100"
        />
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-btn text block color="white" @click="scrollToElement('home')" class="py-8">
      Home
    </v-btn>
    <v-btn text block color="white" @click="scrollToElement('servicios')" class="py-8">
      Servicios
    </v-btn>
    <v-btn text block color="white" @click="scrollToElement('planes')" class="py-8">
      Planes
    </v-btn>
    <v-btn text block color="white" @click="contactModalShow" class="py-8">
      Contacto
    </v-btn>
    <v-btn text block color="white" href="https://usuarios.sosclick.cl/login" target="_blank" class="py-8 mt-4">
      Iniciar Sesión
    </v-btn>
    <v-btn text block color="white" href="https://usuarios.sosclick.cl/register?code=643510432" target="_blank" class="py-8">
      Registro
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
  import {EventBus} from '../../Helpers/events.js'
  export default {
    name: 'Drawer',
    props: ['drawerOpen','changeDrawerStatus'],
    watch: {
      drawerOpen: {
        handler(data) {
          this.drawer = data
        },
        deep: true
      }
    },
    data: () => ({
      drawer: false
    }),
    methods: {
      contactModalShow(){
        this.$store.commit('setModalContact', true)
        this.changeDrawerStatus(false)
      },
      changeDrawer(state){
        this.changeDrawerStatus(state)
      },
      scrollToElement(element){
        EventBus.$emit('scrollToElement', element)
        this.changeDrawerStatus(false)
      },
    }
  }
</script>