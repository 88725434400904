<template>
    <header style="min-height: 70vh; padding-top: 80px; background: transparent linear-gradient(183deg, #E6B54C 0%, #FFFDC1 51%, #B58910 87%, #FFFF83 100%) 0% 0% no-repeat padding-box;" class="v1 relative-box " id="home-area">
      <div class="image-side-box right-box hidden-xs hidden-sm  box-bg">
        <img
          src="../../assets/Camara.png"
          class="layer bg-camara"
          style="width:60%; max-width: 420px;"
          data-depth="0.35"
          alt="SOSCAM"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-5">
            <div class="text-box" style="border-top: solid 2px white;border-bottom: solid 2px white;">
              <h2 style="    line-height: 1.4;font-weight: 800; font-size: 50px; color:#796843;"  v-if="$store.state.roulettePrize && $store.state.roulettePrize.uid == 'percent'">
                GANASTE <br />
                UN {{$store.state.roulettePrize.amount}}% EN TU <br />
                SOSCAM
              </h2>
              <h2 v-else style="    line-height: 1.4;font-weight: 800; font-size: 50px; color:#796843;">
                GANASTE
                <br />
                UNA SOSCAM
              </h2>
              <div class="hr"></div>

              <h3 style="font-weight:600; color:#796843; margin-top:12px;">
                Tu Solución Integral de Seguridad
              </h3>
              <div class="space-10"></div>
              </div>
              <div class="hero-buttons-container">
                <button
                v-if="$store.state.roulettePrize && $store.state.roulettePrize.uid == 'percent'"
                  style="z-index:98; margin-top: 12px;"
                  @click="$router.push({ name: 'SOSCamSingle' })"
                  class="win-btn">
                  Comprar
                </button>
            </div>
          </div>
        </div>
      </div>
    </header>
</template>

<script>


export default {
    data () {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
/*     @import '../../assets/css/style.css';  */
@import "../../assets/css/normalize.css";
/*     @import '../../assets/css/bootstrap-min.css'; */
@import "../../assets/css/owl-carousel-min.css";
@import "../../assets/css/lity-min.css";
@import "../../assets/css/font-awesome-min.css";
@import "../../assets/css/animate.css";
@import "../../assets/css/theme.css";
@import "../../assets/css/helper.css";
@import "../../assets/css/responsive.css";


.title,
.rate {
  color: #d32527;
}
.bttn-1 {
  color: #fff;
  &:hover {
    color: #d32527;
  }
}
.bttn-3 {
  color: #d32527;
  &:hover {
    color: #fff;
  }
}

.bg-camara {
  background-image: url("../../assets/imgs/circle-bg-2.png");
  background-size: cover;
}

.about-section {
  background-image: url("../../assets/image.png");
}
.bttn-2:hover {
  color: #d32527 !important;
}
.top-title {
  font-size: 32px;
  color: rgb(46, 0, 0);
}
.title {
  font-size: 36px !important;
}
@media (max-width: 568px) {
  .intro-area {
    background-size: cover !important;
  }
  .top-title {
    color: #fff;
  }
}

///////////////////////////////////////////////////////////////////////////
//hero
.hero-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .hero-buttons-container {
    flex-direction: row;
  }
}


.win-btn {
background: transparent linear-gradient(261deg, #E6B54C 0%, #FFFDC1 16%, #B58910 87%, #FFFF83 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 29px;
  color: #766948;
  padding: 12px 50px;
  &:hover {
    background-color: #fff;
    color: #d32527;
  }
}

@media (min-width: 768px) {
  .win-btn {
    height: max-content;
  }
}
</style>