<template>
  <v-container style="background: #f7f7f7">
    <v-row>
      <v-col class="pl-16" cols="6">
        <div class="mt-16">
          <h2 class="display-1 font-weight-bold text-primary">SOSCAM</h2>
          <p class="mb-0" style="color: #939393;">
            SOSCAM, es una Cámara de Seguridad IP, que actúa en forma complementaria al Sistema de Seguridad SOSCLICK, todo lo cual permite a todo propietario ya sea de un Almacén, Local Comercial, Hogar, Pyme, etc, monitorear en todo momento dicho lugar geográfico vía la App SOSCLICK, incluso con la posibilidad de generar Audio disuasivo.
          </p>
          <p class="mb-0" style="color: #939393;">
            Además el Sistema SOSCLICK consta de una Aplicación Móvil, que permite ante un acto delictual o cualquier Emergencia que sufra el dueño de casa, comerciante, etc, activar el Botón de Emergencia de la App, generando así, en forma inmediata, diferentes video conferencias simultaneas, no solo con Seguridad Ciudadana, sino que también, con otros Locatarios, Vecinos o Familiares que pertenezcan a su Red de Seguridad Personal.
          </p>
        </div>

        <div class="ml-4 mt-5">
          <v-btn
            large
            outlined
            rounded
            :block="$vuetify.breakpoint.mobile"
            color="#D0282E"
            class="px-6 py-2 my-4"
            style="z-index: 123;"
            @click="$router.push('/soscam')"
          >
            <p class="mb-0">Solicitar Cámara</p>
          </v-btn>
          <v-btn
            large
            text
            :block="$vuetify.breakpoint.mobile"
            color="#D0282E"
            class="mx-2 px-6 py-2 my-4"
            href="/catalogo_soscam.pdf"
            target="_blank"
          >
            <p class="mb-0">Descargar Catálogo</p>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6">
        <v-img
          class="mx-auto mt-16"
          max-width="450"
          :src="require('./../../assets/Camara.png')"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SosCam",
  data() {
    return {};
  },
};
</script>

<style>
</style>